body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainGrid_Container {
   
  text-align: center;
  margin:0, auto;
  padding-left: 20%;
  padding-top: 150px;
  display: grid;
  /* grid-template: 400px / auto auto; */
  grid-template-columns: 400px 400px;
  grid-template-rows: 400px 400px 400px;
  grid-column-gap: 200px;
  grid-row-gap: 40px;  
  white-space: nowrap;
  width: auto;
}

.IllustrationList_Container {
  text-align: left;
  /* margin:0, auto; */
  padding-left: -70%;
   
  /* padding-left: -70%; */

}

.Z_List_Item {
  position: relative;
  white-space: nowrap;
  width: 550px;
  height: 650px; 
}
 
.gridItemContainer {
  position: relative;
  text-align: center;
}

 
.gridItemContainer_expanded {
  position: relative;
  text-align: center;
}

.gridImage {
   
  opacity: 1.0;
   
  border-radius: 3px;
  position: absolute;
  left: 0px;
  top: 20px; 
  width: 550px;
  max-width: 550px;

}
 
.gridBlackSolid {
  border-radius: 3px;
  background-color: #1c2233;
  border-radius: 3px;
  opacity: 0.9;
  position: absolute;
  left: 0px;
  top: 20px; 
  width: 555px;
  height: 360px;
  z-index: 1;

}


.gridDetailsContainer{

  position: absolute;
  left: 10px;
  top: -50px;
  z-index: 2;
  
}

.gridText_Title {
  position: relative;
  opacity: 1.0;
  font-weight: 100;
  font-style: bold;
   
  font-family: 'Poppins', sans-serif;
  line-height: 0px;
  font-size: 25px;
   
  color: rgb(192, 252, 113);
  width: 500px;
  text-align: left;
  white-space:normal;
  
}

.gridText_TagLine {
  opacity: 1.0;
  position: relative;
   
  font-weight: 100;
  font-style: bold;
   
  font-family: 'Poppins', sans-serif;
  line-height: 0;
  font-size: 17px;
   
  color: rgb(255, 153, 0);
  width: 500px;
  text-align: left;
  white-space:normal;
  
}
   
.gridText {
  padding-top: 10px;
  opacity: 1.0;
  position: relative;
   
  font-weight: 100;
  font-style: normal;
   
  font-family: 'Poppins', sans-serif;
  line-height: 25px;
  font-size: 18px;
   
  color: rgb(192, 252, 113);
  width: 500px;
  text-align: left;
  white-space:normal;
  
}

  
.gridTechText {
  opacity: 1.0;
  position: relative;
   
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  line-height: 25px;
  font-size: 25px;
   
  color: rgb(255, 153, 0);
  width: 500px;
  text-align: left;
  white-space:normal;
  
}

.gridProjectLinks {
  opacity: 1.0;
  position: relative;
  font-weight: 100;
  font-style: bold;
   
  font-family: 'Poppins', sans-serif;
  line-height: 22px;
  font-size: 22px;
   
  color: rgb(255, 73, 143);
  width: 500px;
  text-align: left;
  white-space:normal; 
}

.gridProjectGitImage {
  position: absolute;
  width: 40px;
  top: 360px;
  left:0px;
}



.App {
  text-align: center;
  margin: 0, auto;
  box-sizing: border-box;
  display: block; 
  width:100%;
  height:100%;
  min-height: 1000px;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  background-color: #1c2233;

  background: fill;
 
    
}



.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  pointer-events: none;
}

.App-header {
   
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: left;
          justify-content: left;
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.AppearTransition-appear {
  opacity: 0.01;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
}

.AppearTransition-appear.AppearTransition-appear-active {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
 

.AboutContainer {
  padding-top: 144px;
  text-align: center; 
  min-height: 10vh;
  padding-right: 30%;
  padding-left: 30%; 
}

.AboutText {
  font-weight: 100;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
   
  line-height: 25px;
  font-size: 18px;
  /* font-size: calc(5px + 2vmin); */
  color: rgb(192, 252, 113);
  width: 100%;
 
  text-align: left; 
  
}

.AboutImages {
  border-radius: 5px;
}



.AboutSkillsTitle {
  width: 100%;
  text-align: center;
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  line-height: 38px;
  text-decoration: none;
  /* font-size: calc(25px + 2vmin); */
  font-size: 32px; 
  color: rgb(192, 252, 113);

}
 
   
  
.Dashboard {

    font-weight: 100;
    font-style: normal;
    font-family: 'acumin-pro', sans-serif;
    src: url('https://use.typekit.net/eqc6pvb.css'); 
    /* background-color: #282c34; */
     
    width: 100%;
  
    text-align: center;
    /*margin:0, auto;
    /* padding: 10px; */
  
    font-size: calc(8px + 2vmin);
    color: rgb(192, 252, 113);
  
}


.FooterContainer {
  text-align: center;
  margin:0, auto;
  
  display: block;
  white-space: nowrap;
  
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  /* font-size: calc(8px + 2vmin); */
  font-size: 25px;
  color: rgb(255, 153, 0);
   
}


.FooterContacts, li {

  display: inline;
  white-space: nowrap;
  margin: 10px;

}

.FooterListItem {
     
  list-style: none;
  display: inline;
  white-space: nowrap;
  margin: 10px;
  padding: 15px;

}

.IllustrationList li {
  list-style: none;
}
 
.Links {

  text-decoration: none;
  color: rgb(192, 252, 113);
 
}

.Links:hover {

  color: rgb(255, 73, 143);
 
}
 
.ListGeneral {
  display: block;
  list-style: none;
}

.ListItemGeneral {
  display: block; 
  text-align: left;
}

.ListItemTitle {
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  line-height: 5px;
  text-decoration: none;
  /* font-size: calc(15px + 2vmin); */
  font-size: 18px;
  color: rgb(192, 252, 113);
}

.ListItemTitle:hover {
  color: rgb(255, 73, 143);
}
 

.NavBarContainer {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #1c2233;
   
  /* filter: drop-shadow(0 0 0.55rem rgb(1, 1, 2)); */
  margin: 0px auto;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  height: auto;
  padding-bottom: 20px;
   
  /* margin-right: 5%; */
  text-align: center; 

  z-index: 2;
   
}

 
.NavBarText_container {
  /* font-weight: 100;
  font-style: normal; */
  /* font-family: 'acumin-pro', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css');  */
  text-decoration: none;
  display: inline;
    
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  /* font-size: calc(12px + 2vmin); */
  font-size: 25px;
}

.NavBarText {
  text-decoration: none;
  color: rgb(255, 153, 0);
}

.NavBarText:hover {
  text-decoration: none; 
  color: rgb(255, 0, 98);
}

.PageIntroContainer {
  margin: 0px auto;
  text-align: center; 
}

.PageIntroItemTitle {
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  line-height: 1px;
  text-decoration: none;
  font-size: calc(15px + 2vmin);
  color: rgb(192, 252, 113);
  text-align: center;
}

.PageIntroItemText {
  font-weight: 100;
  font-style: normal;
  font-family: 'acumin-pro', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css');
  line-height: 15px;
  font-size: calc(3px + 2vmin);
  color: rgb(192, 252, 113);
  text-align: center;
}
 

.ProjectPageContainer {
  margin-top: 370px;
  margin: 0, auto;
  text-align: center; 
  /* background-color:rgb(126, 95, 95); */
   
}

.ProjectContainer {
    
  margin: 0, auto;
  text-align: center; 
  /* min-height: 10vh; */
  padding-top: 80px;
   
}

.ProjectTextContainer {
  
  text-align: center;
  white-space:normal;
  padding-left: 30%;
  padding-right:30%;
}

.ProjectText {
  font-weight: 100;
  font-style: normal;
  /* font-family: 'acumin-pro', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); */
  font-family: 'Poppins', sans-serif;
  line-height: 25px;
  font-size: 18px;
  /* font-size: calc(5px + 2vmin); */
  color: rgb(192, 252, 113);
  width: 100%;
  text-align: left;
  white-space:normal;
   
}

.projectThumbImages {
  border-radius: 5px;
  width: 150px;
}

.ProjectImages {
  text-align: center;
  border-radius: 5px;
  width: 50%;
}

.ProjectLinks {
  font-weight: 100;
  
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css');
  font-size: 25px;
  color: rgb(255, 147, 23);
  text-decoration: none;

 
}

.ProjectLinks:hover {
  text-decoration: none;
  color: rgb(255, 73, 143);
}


.ProjectGitImage {
  width: 40px;
}

.ProjectLinksContainer {

  margin: 0, auto;
  text-align: center;
  width:100%;
 
}

.ProjectListItemTitle {
  text-align: center;
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  line-height: 1px;
  text-decoration: none;
  /* font-size: calc(25px + 2vmin); */
  font-size: 35px; 
  color: rgb(192, 252, 113);

}


.ProjectListItemTagline {
  text-align: center;
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  line-height: 1px;
  text-decoration: none;
  /* font-size: calc(8px + 2vmin); */
  font-size: 25px; 
  color: rgb(255, 147, 23);

}
 
.SkillListContainer {
  width: 100%;
  /* display: inline; */
  padding: 0px;
  margin-left: 0px;
  text-align: center;
  vertical-align: top;   
}


.SkillList {
text-align: left;
font-weight: 100;
font-style: bold;
font-family: 'acumin-pro-condensed', sans-serif;
src: url('https://use.typekit.net/eqc6pvb.css'); 
font-size: calc(12px + 2vmin);
color: rgb(255, 153, 0);
padding: 7px;
vertical-align: top; 
display: inline-block;

 
}

.SkillListItem {
font-weight: 100;
font-style: bold;
/* font-family: 'acumin-pro', sans-serif;
src: url('https://use.typekit.net/eqc6pvb.css'); */
font-family: 'Poppins', sans-serif;
line-height: 25px;
font-size: calc(10px + 2vmin);
color: rgb(255, 224, 83);
list-style: none;
display: block;
text-align: left;  
margin-left: -1px;
}



.ThinType {
  font-weight: 100;
  font-style: normal;
  font-family: 'acumin-pro', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css');
  font-size: calc(6px + 1.5vmin);
  color: rgb(192, 252, 113);
}
 
.RColeDevImage {

  text-align: left;
  width: 320px;
  margin-top: 0.5rem;
 
}

.WelcomeContainer {
  margin-top:150px;
  text-align:center;
   
 
}

.WelcomeText {
  font-weight: 50;
  font-style: normal;
   
  font-family: 'Poppins', sans-serif;
  line-height: 25px;
  font-size: calc(7px + 2vmin);
  color: rgb(192, 252, 113);
  max-width: 70%;
  margin: 0px auto;
  text-align: center; 
 
}
  

@media (min-width: 601px) and (max-width: 1500px) {

  .IllustrationList {
    margin-left: -150px;
    /* position: left; */
    list-style: none;
    display: inline;
    /* white-space: nowrap; */
    /* margin: 10px; */
    padding-right: 45px;
  }

  .mainGrid_Container {
    text-align: center;
    margin:0, auto;
    padding-left: 20%;
     
    display: grid;
    /* grid-template: 400px / auto auto; */
    grid-template-columns: 400px;
    grid-template-rows: 400px 400px 400px 400px 400px 400px;
    grid-column-gap: 200px;
    grid-row-gap: 40px;  
    white-space: nowrap;
    width: auto;
  }
 
  .AboutContainer { 
    padding-top: 210px;
    text-align: center; 
    min-height: 10vh;
    padding-right: 30%;
    padding-left: 30%; 
  }
 
  .FooterContacts {
     
    list-style: none;
    display: block;
    white-space: nowrap;
    margin: 10px;
    padding: 15px;
 
  }

  .FooterListItem {
     
    list-style: none;
    display: block;
    white-space: nowrap;
    margin: 10px;
    padding: 15px;
 
  }
 
  .ProjectTextContainer {
    text-align: center;
    white-space:normal;
    padding-left: 25%;
    padding-right:25%;
  } 
 
  .SkillListContainer {
    width: 100%;
    /* display: inline; */
    padding: 0px;
    margin-left: 0px;
    text-align: center;
    vertical-align: top;   
  }
 
  .SkillList {
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  font-size: calc(12px + 2vmin);
  color: rgb(255, 153, 0);
  padding: 7px;
  vertical-align: top; 
  display: inline-block;
  
   
  }

  .SkillListItem {
  font-weight: 100;
  font-style: bold;
  /* font-family: 'acumin-pro', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); */
  font-family: 'Poppins', sans-serif;
  line-height: 25px;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 224, 83);
  list-style: none;
  display: block;
  text-align: left;  
  margin-left: -1px;
  }
  
}
 

@media (min-width: 0px) and (max-width: 600px) {
 
  .mainGrid_Container {
    position: relative;
    padding-left: 2%;
    padding-right: 1%;
    /* text-align: center; */
    /* margin:0, auto; */
      
    display: grid;
    /* grid-template: 400px / auto auto; */
    grid-template-columns: 400px;
    grid-template-rows: auto auto auto auto auto auto auto;
     
    grid-row-gap: 80px;  
    white-space: nowrap;
    width: auto;
  }
  
  .Z_List_Item {
    position: relative;
    white-space: nowrap;
   
  }
   
  .gridItemContainer {
    position: relative;
    text-align: left;
    width: 350px;
    height: 220px;  
  }

  .gridItemContainer_expanded {
    position: relative;
    text-align: left;
    width: 350px;
    height: 420px;  
  }
  
  .gridImage {
    opacity: 1.0;
    border-radius: 3px;
    /* left: -70px; */
    position: relative;
    top: 20px; 
    width: 300px; 
  }
   
  .gridBlackSolid {
    border-radius: 3px;
    background-color: #1c2233;
    border-radius: 3px;
    opacity: 0.9;
    position: absolute;
    /* left: -50px; */
    top: 20px; 
    width: 305px;
    height: 260px;
    z-index: 1;
  }
  
  
  .gridDetailsContainer{
  
    position: absolute;
    /* left: -45px; */
    margin: 0, auto;
    top: -50px;
    z-index: 2;
    
  }
  
  .gridText_Title {
    position: relative;
    opacity: 1.0;
    font-weight: 100;
    font-style: bold;
    /* font-family: 'acumin-pro', sans-serif;
    src: url('https://use.typekit.net/eqc6pvb.css'); */
    font-family: 'Poppins', sans-serif;
    line-height: 0px;
    font-size: 25px;
    /* font-size: calc(5px + 2vmin); */
    color: rgb(192, 252, 113);
    width: 500px;
    text-align: left;
    white-space:normal;
    
  }
  
  .gridText_TagLine {
    opacity: 1.0;
    position: relative;
     
    font-weight: 100;
    font-style: bold;
    /* font-family: 'acumin-pro', sans-serif;
    src: url('https://use.typekit.net/eqc6pvb.css'); */
    font-family: 'Poppins', sans-serif;
    line-height: 0;
    font-size: 17px;
    /* font-size: calc(5px + 2vmin); */
    color: rgb(255, 153, 0);
    width: 280px;
    text-align: left;
    white-space:normal;
    
  }
     
  .gridText {
    padding-top: 10px;
    opacity: 1.0;
    position: relative;
    /* position: absolute;
    left: 10px;
    top: 14px; */
    font-weight: 100;
    font-style: normal;
    /* font-family: 'acumin-pro', sans-serif;
    src: url('https://use.typekit.net/eqc6pvb.css'); */
    font-family: 'Poppins', sans-serif;
    line-height: 25px;
    font-size: 18px;
    /* font-size: calc(5px + 2vmin); */
    color: rgb(192, 252, 113);
    width: 280px;
    text-align: left;
    white-space:normal;
    
  }
  
    
  .gridTechText {
    opacity: 1.0;
    position: relative;
    /* position: absolute;
    left: 10px;
    top: 14px; */
    font-weight: 100;
    font-style: bold;
    font-family: 'acumin-pro-condensed', sans-serif;
    src: url('https://use.typekit.net/eqc6pvb.css'); 
    line-height: 25px;
    font-size: 25px;
    /* font-size: calc(5px + 2vmin); */
    color: rgb(255, 153, 0);
    width: 280px;
    text-align: left;
    white-space:normal;
    
  }
  
  .gridProjectLinks {
    opacity: 1.0;
    position: relative;
    /* position: absolute;
    left: 10px;
    top: -23px; */
    font-weight: 100;
    font-style: bold;
    /* font-family: 'acumin-pro', sans-serif;
    src: url('https://use.typekit.net/eqc6pvb.css'); */
    font-family: 'Poppins', sans-serif;
    line-height: 0;
    font-size: 22px;
    /* font-size: calc(5px + 2vmin); */
    color: rgb(255, 73, 143);
    width: 380px;
    text-align: left;
    white-space:normal;
    
  }
  
  .gridProjectGitImage {
    position: relative;
    width: 40px;
    left:0px;
    top: -10px;
  }
   
   
  .AboutContainer {
    padding-top: 195px;
    margin: 0, auto;
    text-align: center; 
    min-height: 10vh;
    padding-right: 10%;
    padding-left: 10%;
  
  }
 
  .AboutText {
   
    text-align: center;
    margin: 0, auto; 
    font-weight: 50;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    /* src: url('https://use.typekit.net/eqc6pvb.css'); */
    line-height: 30px;
    /* font-size: calc(13px + 2vmin); */
    font-size: 18px; 
    color: rgb(192, 252, 113);
    /* max-width: 90%; */
    /* margin-left: 13%; */
    text-align: left; 
     
  }
  
  .FooterContainer {
    
    text-align: center;
    margin:0, auto;
    width: 300px; 
    display: inline-block;
    white-space: nowrap;
    
    font-weight: 100;
    font-style: bold;
    font-family: 'acumin-pro-condensed', sans-serif;
    src: url('https://use.typekit.net/eqc6pvb.css'); 
    /* font-size: calc(16px + 2vmin); */
    font-size: 25px;
    color: rgb(255, 153, 0);
    /* color: rgb(0, 225, 255); */
  }

  .FooterContacts {
     
    list-style: none;
    display: block;
    white-space: nowrap;
    margin: 10px;
    padding: 15px;
 
  }

  .FooterListItem {
     
    list-style: none;
    display: block;
    white-space: nowrap;
    margin: 10px;
    padding: 15px;
 
  }

  .IllustrationList li {
    margin-left: -30px;
    list-style: none;
    display: block;
    white-space: nowrap;
    /* margin: 10px; */
    padding: 15px;
  }
  
   
.Links {
  text-decoration: none;
  color: rgb(192, 252, 113);
 
}

.Links:hover {
  text-decoration: none;
  color: rgb(255, 73, 143);
 
}
 
.ListItemGeneral {
  display: block; 
  text-align: left;
}
 
.NavBarText_container {
  font-weight: 100;
  font-style: normal;
  font-family: 'acumin-pro', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  text-decoration: none;
  display: inline;
  margin-left: -35px; 
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  font-size: calc(18px + 2vmin);
}

.NavBarText {
  text-decoration: none;
  color: rgb(255, 153, 0);
}

.NavBarText:hover {
  /* font-weight: 350;
  font-style: bold;
  font-family: 'acumin-pro', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css');  */
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  font-size: calc(18px + 2vmin);
  text-decoration: none;
  color: rgb(255, 0, 98);
}
 
.ProjectPageContainer {
  padding-top: 50px;
  width: 100%;
}
 
.ProjectContainer {  
  margin: 0, auto;
  text-align: left; 
  min-height: 10vh;
  padding: 0px;
}
  
.ProjectTextContainer {
  text-align: left;
  white-space: normal;
  width:100%;
  padding:0px;
}
 
.ProjectText {
  font-weight: 100;
  font-style: normal;
  /* font-family: 'acumin-pro', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); */
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-size: calc(15px + 2vmin);
  color: rgb(192, 252, 113);
  width: 100%;
  text-align: left;
  white-space:normal;

  width:100%;
  padding:0px;
   
}
 
.ProjectImages {
  text-align: center;
  border-radius: 5px;
  width: 100%;
}

.ProjectLinksContainer {

  margin: 0, auto;
  text-align: center;
  width:100%;
 
}

.ProjectLinks {
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css');
  font-size: calc(15px + 2vmin);
  color: rgb(255, 147, 23);
  text-decoration: none;
  margin: 0, auto;
  text-align: center;
  width:100%;
  padding:0px;
}

.ProjectLinks:hover {
  text-decoration: none;
  color: rgb(255, 73, 143);
}

.ProjectGitImage {
  width: 13%;
}

.ProjectListItemTitle {
  text-align: center;
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  line-height: 1px;
  text-decoration: none;
  font-size: calc(32px + 2vmin);
  color: rgb(192, 252, 113);
}
 
.ProjectListItemTagline {
  text-align: center;
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  line-height: 1px;
  text-decoration: none;
  font-size: calc(17px + 2vmin);
  color: rgb(255, 147, 23);
}

.SkillListContainer {
  width: 100%;
  /* display: inline; */
  padding: 0px;
  margin-left: 0px;
  text-align: center;
  vertical-align: top; 
}
  
.SkillList {
  font-weight: 100;
  font-style: bold;
  font-family: 'acumin-pro-condensed', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); 
  font-size: calc(12px + 2vmin);
  color: rgb(255, 153, 0);
  padding: 7px;
  vertical-align: top; 
  display: inline-block;
}

.SkillListItem {
  font-weight: 100;
  font-style: bold;
  /* font-family: 'acumin-pro', sans-serif;
  src: url('https://use.typekit.net/eqc6pvb.css'); */
  font-family: 'Poppins', sans-serif;
  line-height: 25px;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 224, 83);
  list-style: none;
  display: block;
  text-align: left;  
  margin-left: -1px;
}

.WelcomeContainer {
  padding-top: 35px;
  text-align: center;
  /* margin: 0px auto; */
 
}

.WelcomeText {
    font-weight: 50;
    font-style: normal;
    font-family: 'acumin-pro', sans-serif;
    src: url('https://use.typekit.net/eqc6pvb.css');
    line-height: 25px;
    font-size: calc(9px + 2vmin);
    color: rgb(192, 252, 113);
    max-width: 90%;
    margin: 0px auto;
    
    text-align: center; 
}

  
}
